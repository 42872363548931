@import "../../../styles/_variables.module.scss";

.onboarding {
	padding: 64px 24px 0px 24px;
	.ttOnboarding {
		color: $AzulPrincipal;
		font: $ttSeccionMobile;
		font-style: normal;
		line-height: 24px;
		text-align: center;
		margin-bottom: 0px;
	}
	.contenedorOnboarding {
		padding-top: 24px;
		.contenedorItem {
			color: $negro;
			margin-bottom: 16px;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px rgba(112, 144, 176, 0.1);
			border-radius: 10px;
			padding: 16px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.contenedorIcono {
				padding-right: 16px;
			}
			.ttItem {
				font-weight: 700;
				font-size: 16px;
				line-height: 21px;
				margin-bottom: 0px;
				align-items: center;
			}
			.contenidoOnb {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 0px;
				text-align: left;
				p {
					margin-bottom: 0px;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.onboarding {
		padding: 50px 10px 0px 10px;
		.ttOnboarding {
			color: $AzulPrincipal;
			font: $ttSeccionMobile;
		}
		.contenedorOnboarding {
			padding-top: 10px;
			display: flex;
			align-items: center;
			.imagen {
				background-image: url("../../../imagenes/imagen-onboarding.jpg");
				background-repeat: no-repeat;
				background-size: contain;
				width: 100%;
				height: 350px;
			}
			.contenedorInfo {
				margin-top: 31px;
				margin-bottom: 21px;
				.contenedorItem {
					color: $negro;
					margin-bottom: 10px;
					background: #FFFFFF;
					box-shadow: 0px 0px 10px rgba(112, 144, 176, 0.1);
					border-radius: 10px;
					padding: 14px;
					.contenedorIcono {
						padding-left: 14px;
						padding-right: 0px;
					}
					.contenido {
						padding-left: 14px;
						.ttItem {
							font-weight: 600;
							font-size: 16px;
							line-height: 30px;
							margin-bottom: 0px;
							align-items: center;
						}
						.contenidoOnb {
							font-size: 14px;
							line-height: 19px;
							p {
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
		.classMegafono,
		.classBinoculares,
		.classBurbujaChat,
		.classSonrisa {
			height: 40px;
			width: 40px;
			min-width: 40px;
		}
	}
}

@media (min-width: 992px) {
	.onboarding {
		padding: $espaciado-112 0px 0px 0px;
		.ttOnboarding {
			color: $AzulPrincipal;
			font: $ttSeccionDesktop;
			font-style: normal;
			line-height: 38px;
			text-align: center;
			margin-bottom: 0px;
		}
		.contenedorOnboarding {
			padding-top: $espaciado-24;
			display: flex;
			align-items: center;
			.imagen {
				background-image: url("../../../imagenes/imagen-onboarding.jpg");
				background-repeat: no-repeat;
				background-size: contain;
				width: 70%;
				height: 672px;
				margin-left: 30px;
			}
			.contenedorInfo {
				margin-top: 41px;
				margin-bottom: 17px;
				.contenedorItem {
					color: $negro;
					margin-bottom: $espaciado-24;
					background: #FFFFFF;
					box-shadow: 0px 0px 10px rgba(112, 144, 176, 0.1);
					border-radius: 10px;
					padding: $espaciado-16;
					margin-right: 136px;
					display: flex;
					flex-direction: row;
					align-items: center;
					.contenedorIcono {
						padding-left: $espaciado-16;
						padding-right: 0px;
					}
					.contenido {
						padding-left: $espaciado-16;
						.ttItem {
							font-weight: 600;
							font-size: 24px;
							line-height: 30px;
							margin-bottom: 8px;
							align-items: center;
						}
						.contenidoOnb {
							font-size: 16px;
							line-height: 22px;
							font-weight: 400;
							p {
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
		.classMegafono,
		.classBinoculares,
		.classBurbujaChat,
		.classSonrisa {
			height: 50px;
			width: 50px;
			min-width: 50px;
		}
	}
}