@import "../../../styles/_variables.module.scss";

.notFoundAlert {
	position: absolute;
	top: auto;
	margin-top: $espaciado;
	background: #FFF3F4;
	width: 90%;
	height: 63px;
	/* Error */
	border: 1px solid #E63946;
	border-radius: 10px;
	padding: 10px 0px 10px 10px; 
	display: flex;
	align-items: center;


	svg {

		display: inline-block;
		vertical-align: top;
	}

	.notFoundAlertText {
		width: 80%;
		box-sizing: border-box;

		margin: 35px 20px 20px 10px;
		font-size: 1rem;
		vertical-align: top;
		display: inline-block;
		line-height: 16px;


		label {
			font-weight: 600;
			color: $negro;
		}

		p {
			margin: none;
			color: $gris;
		}
	}
}

@media (min-width: 576px) {
	.notFoundAlert {
		width: 250px;

		.notFoundAlertText {
			width: 193px;
			font-size: 12px;
		}
	}
}