@import "../../../styles/_variables.module.scss";

.contenedorCarrusel {
	display: flex;
	align-items: center;
}

.contenedorSlide {
	padding-left: 24px;
	padding-right: 0px;
}

.contenedorSegundario {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	max-width: 500px;
}

.contenedorBanner {
	margin-top: $espaciado-48;
	margin-top: 40px;
}

.banner {
	width: auto;
	height: 150px;
	border-radius: 10px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; //comprobar con img real
}

.arrowNext,
.arrowPrev {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
	width: 48px !important;
	height: 48px !important;
	border-radius: 50%;
	color: $AzulPrincipal  !important;
}

.arrowPrev {
	display: none;

	svg {
		transform: rotate(90deg);
	}
}

.arrowNext {
	display: none;
	margin-right: 30px;

	svg {
		transform: rotate(-90deg);
	}
}

@media (min-width: 425px) {
	.banner {
		height: 175px;
	}
}

@media (min-width: 520px) {
	.banner {
		height: 220px;
	}
}

@media (min-width: 768px) {
	.banner {
		height: 300px;
	}
}

@media (min-width: 992px) {
	.contenedorSlide {
		padding-left: 0px;
		width: 90%;
	}

	.arrowNext,
	.arrowPrev {
		display: flex;
		margin: auto;
	}

	.banner {
		width: auto !important;
		height: 230px;
	}
}

@media (min-width: 1024px) {
	.banner {
		height: 230px;
	}
}

@media (min-width: 1400px) {
	.banner {
		height: 300px;
	}
}