@import "../../../styles/_variables.module.scss";

.contenedorCard {
	font-family: $default-font;

	.card {
		border: none;
		box-shadow: 0px 8px 17px rgba(112, 144, 176, 0.15);
		border-radius: 10px;

		.cardFoto {
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;

			img {
				height: 190px;
				width: 100%;
				object-fit: cover;
			}

			.destacado {
				position: absolute;
				top: $espaciado-16;
				left: 0;
				background-color: $verde;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				padding: 2px $espaciado 2px $espaciado-16;
				font: normal 600 14px/21px $default-font;
				color: $AzulPrincipal;
			}

			.like {
				position: absolute;
				top: $espaciado-16;
				right: $espaciado-16;
			}
		}

		.contenedorTTimg {
			display: flex;

			.infoPrincipal {
				width: 100%;
				margin-bottom: $espaciado;

				.comuna {
					font-size: 12px;
					line-height: 16px;
					font-feature-settings: "liga";
					color: $gris;
					margin-bottom: $espaciado;
					display: flex;
					align-items: center;

					svg {
						margin-right: 4px;
					}
				}

				.infoTipo {
					width: fit-content;
					background: #f4f4f4;
					border-radius: 4px;
					font: normal 600 12px/16px $default-font;
					padding: 0 $espaciado;

					.txtTipo {
						color: $gris;
						margin: 0;
					}

					.txtOperacion {
						color: $AzulPrincipal;
					}
				}
			}

			.contenedorLogo {
				width: 70px;
				margin-top: -50px;
				background-color: $blanco;
				box-shadow: 0px 0px 10px rgba(112, 144, 176, 0.25);
				border-radius: 4px;
				//monitorear
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				right: 17px;
				height: 70px;
				padding: 3px;
				contain: content;

				.logoCard {
					width: 100%;
					height: auto;
				}
			}
		}

		.nombre {
			font: normal 600 16px/22px $default-font;
			color: $negro;
			margin-bottom: $espaciado;
			height: 46px;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.separador {
			border-top: 1px solid #e9ecef;
			background-color: #e9ecef;
			margin-bottom: $espaciado;
		}

		.precios {
			.txtLabel {
				font: normal 400 14px/22px $default-font;
				color: $gris;
				margin: 0;
			}

			.txtPrecioUf {
				font: normal 700 18px/24px $default-font;
				color: $negro;
				margin-bottom: $espaciado-16;
			}
		}

		.programa {
			font-size: 0.875rem;
			display: flex;
			flex-direction: row;
			padding: 0;
			margin-bottom: $espaciado;

			li {
				font: normal 400 12px/22px $default-font;
				color: $negro;

				p {
					padding: 0 14px 0 5px !important;
				}

				.superficie {
					padding-right: 0 !important;
				}
			}

			.dormitorios,
			.banos,
			.superficieUtil {
				display: flex;
				align-items: center;
			}

			.dormitorios,
			.banos,
			.superficieUtil {
				p {
					margin: 0;
					font-size: 12px;
					padding: 10px 0;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.comuna {
		font-size: 14px !important;
		line-height: 19px !important;
	}

	.nombre {
		font-size: 18px !important;
		line-height: 24px !important;
	}

	.programa {
		margin-bottom: $espaciado-16 !important;
	}

	.precios {
		.txtPrecioUf {
			font-size: 24px !important;
		}
	}
}