@import "../../../styles/_variables.module.scss";

.contenedorProductos {
    padding-top: $espaciado-64;
    .ttProductos {
        font: $ttSeccionMobile;
        color: $AzulPrincipal;
        text-align: center;
        // margin-bottom: 20px;
    }
    .subttProducto {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $gris;
        margin-bottom: $espaciado-24;
    }  
}

@media (min-width: 992px) {
    .contenedorProductos {
        padding-top: $espaciado-112;
        .ttProductos {
            margin-bottom: 10px;
            font: $ttSeccionDesktop;
        }
        .subttProducto {
            display: block;
            color: $negro;
            font-family: $default-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin-bottom: $espaciado-24;
        }
    }
}

.cardBody {
	display: flex;
	flex-flow: column;
}
