@import "../../../styles/_variables.module.scss";

.contenedorBuscador {
	background-color: $AzulPrincipal;
	font-family: $default-font;
	padding-top: $espaciado-32;
	padding-left: 10px;
	padding-right: 10px;

	.contenedorTitulo {
		margin-bottom: $espaciado-16;
	}

	.ttBuscador {
		color: $blanco;
		font-style: normal;
		font-size: 32px;
		line-height: 100%;
		margin: 0;
		font-weight: 600;
	}

	.ttBuscador2 {
		color: $blanco;
		font-style: normal;
		font-size: 32px;
		line-height: 100%;
		margin: 0;
		font-weight: 600;
	}

	.subttCodigo {
		font: normal 400 18px $default-font;
		line-height: 18px;
		color: $blanco;
		padding-top: 25px;
	}

	.contenedorOpciones {
		display: flex;
		justify-content: space-between;
		padding-bottom: $espaciado-16;
	}

	.formControlDireccionContainer {
		position: relative
	}

	.formControl {
		font: normal 400 14px $default-font;
		min-height: 40px;
	}

	.formControlCodigo {
		border-radius: 4px;
		font: normal 400 14px $default-font;
	}

	.btnBuscar {
		margin-top: $espaciado-24;
		cursor: pointer;
		background: $verde;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		padding: $espaciado;
		color: $AzulPrincipal;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
	}

	.btnBuscar:hover {
		background-color: $verdeHover;
	}

	.buscarCodigo {
		cursor: pointer;
		margin-top: $espaciado;
		font: normal 400 $font-size-m $default-font;
		display: flex;
		align-items: center;
		color: $blanco;
		text-decoration: underline;
		width: fit-content;
	}
}

.dropdownMenu {
	min-width: -webkit-fill-available;
}

.btn {
	background-color: $blanco;
	color: $negro;
	font-size: 15px;
	min-width: 136px;
	padding: 0.375rem 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 40px;
}

.mobile {
	display: block;
	display: flex;
	justify-content: center;
	padding-top: $espaciado-16;
}

.highlightSearchFocus {
	background-color: $colorSecundario  !important;
}

.highlightSearch:hover,
.highlightSearch:focus {
	background-color: $colorSecundario;
}

.highlightSearch {
	background-color: white;

	:hover,
	:focus {
		background-color: $colorSecundario;
	}

	:active,
	:active span,
	:active label {
		color: rgba(255, 255, 255, 0.8) !important;
		background-color: $AzulPrincipal;
	}

	:active path {
		fill: rgba(255, 255, 255, 0.8) !important;
	}

	:active path:nth-child(1) {
		fill: $colorSecundario  !important;
	}
}

.formControlDireccionContainer * {
	transition: color cubic-bezier(0.455, 0.03, 0.515, 0.955) 100ms,
		background-color cubic-bezier(0.455, 0.03, 0.515, 0.955) 100ms,
		fill cubic-bezier(0.455, 0.03, 0.515, 0.955) 100ms;
}

@media (min-width: 576px) {
	.contenedorOpciones {
		display: flex;
		justify-content: space-around !important;
		padding: 30px 0;
	}
}

@media (min-width: 992px) {
	.contenedorBuscador {
		display: flex;
		//align-items: center;
		padding-top: 120px;
		background-image: url("../../../imagenes/fondo-buscador.jpg");
		height: 60vh;
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: cover;

		.mobile {
			display: none;
		}

		.contenedorTitulo {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			.ttBuscador {
				font-weight: 700;
				font-size: 36px;
			}

			.ttBuscador2 {
				font-weight: 700;
				font-size: 36px;
			}
		}

		.subttCodigo {
			display: none;
		}

		.contenedorDesktop {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-top: 30px;

			.contenedorOpciones {
				display: flex;
				justify-content: space-around;
				padding: 0;
			}

			.formControl {
				border-radius: 0;
				font-size: 18px;
				line-height: 37px;
				border: none;
				padding: 0.375rem;
			}

			.formControlCodigo {
				border: none;
				border-radius: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				line-height: 37px;
				font-size: 18px;
				padding-left: 2rem;
			}

			.item {
				font: normal 400 18px $default-font;
				color: $negro;
				pointer-events: none;
			}

			.chevron {
				pointer-events: none;
			}

			.btnBuscar {
				margin-top: 0;
				letter-spacing: 0.03em;
				font: normal 400 16px/37px $default-font;
				min-width: 196px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				padding: 6px;
			}

			.btn {
				background-color: $blanco;
				border: none;
				border-right: 1px solid rgba(0, 0, 0, 0.2);
				border-radius: 0;
				padding: 6px 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 37px;
				font-weight: 400;
				font-size: 18px;
				min-width: 180px;
				min-height: 49px;
			}

			.btnBorde {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
	}
}

@media (max-width: 992px) {
	.contentGroupDropdown{
		width: 100%;

		.dropdownCustom{
			display: grid;

			.itemFullWith{
				width: 100%;
				text-align: left;
			}
		}

		&:first-child{
			padding-right: 20px;
		}

		.contentDropDownMenuCustom{
			margin-top: 40px;
		}
	}
}