@import "../../../styles/_variables.module.scss";

.contenedorPublicar{
    display: flex;
    flex-direction: column;
    background-color: $colorSecundario;
    padding: 37px 15px;
    color: $negro;
    border-radius: 10px;
    margin-bottom: 64px;
		margin-top: $espaciado-64;
    .contenedorTitulo{
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        span{
            color: $AzulPrincipal;
        }
    }
    .btnCta {
        background: $verde;
        color: $AzulPrincipal;
        font: normal 600 14px $default-font;
        &:hover{
            background-color: $verdeHover;
        }
    }

    .chevrones{
        display: none;
    }
}

@media (min-width: 992px){
    .contenedorPublicar{
        padding: 77px 25px;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        font-size: 20px;
		margin-top: $espaciado-112;

        .btnCta{
            font-size: 18px;
            width: 244px;
        }
        .contenedorTitulo{
            width: 21%;
        }
        .contenedorBajada{
            width: 40%;
        }
        .chevrones{
            display:inline;
            padding-left: 10px;
            svg{
                transform: rotate(270deg);
            }
        }
    }
}