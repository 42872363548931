@import "../../../styles/_variables.module.scss";

.contenedorPrincipal {
	font-family: $default-font !important;
	padding-bottom: 16px;

	h2 {
		text-align: center;
		font-weight: 600;
		margin-bottom: 32px;
	}

	h3 {
    text-align: center;
    font-weight: 700;
		margin-bottom: 24px;
	}
}

.contenedorUl {
	padding: 0;

	.noBulletPoints {
		list-style-type: none;
		padding: 0 0 8px 0;

		a {
			color: $negro;
			font-size: 14px;
			font-family: $default-font !important;
			width: 100%;
			display: block;
		}
	}
}

.labelRegion {
	font-size: 16px;
	font-weight: 600;
	color: $AzulPrincipal;
	font-family: $default-font !important;
	margin-bottom: 8px;
}

.preFooterLinkContainer {
	display: flex;
	overflow: hidden;
	transition: opacity 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.preFooterLinkContainerMobile {
	flex-direction: column;
	.contenedorUl {
		padding: 0;
		.noBulletPoints {
			padding: 8px 0 8px 0;
		}
	}
}

.hidden ul {
	display: none;
}

.preFooterLink {
	width: 290px;
	margin-right: 32px;
}

.preFooterLinkContainerMobile .preFooterLink {
	width: 100%;
	margin: 0;
}

.preFooterLinkContainerMobile .preFooterLink ul {
	border: 1px solid $neutral-300-value;
	padding: 0px 16px 16px 16px;
	border-radius: 10px;
	margin-top: -14px;
	padding-top: 8px;
}

.hide {
	opacity: 0;
	height: 0%;
	// width: 0%;
	position: absolute;
}

button.tabHeader {
	font-size: 20px;
	font-family: "nunito";
	text-transform: capitalize;
	color: #666;
	padding: 8px 16px;
	padding-left: 0;
	border-style: none;
	background-color: white;
	border-bottom: solid 3px;
	border-bottom-color: rgba(0, 0, 0, .1);
	min-width: 90px;
	text-align: center;
	margin-bottom: 24px;
}

button.secondaryTabHeader {
	font-size: 18px;
}

button.activeButton {
	color: $AzulPrincipal;
	border-bottom: solid $AzulPrincipal 3px;
	font-weight: 600;
}


// Mobile styles
.contenedorOperacion{
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;

	button{
		width: 100%;
	}
}

.contenedorPropiedad {
	margin-top: 8px;

	.chevronArrowDown {
		display: inherit;
	}
}

.nativeSelect {
	width: 100%;
	border-radius: 10px;
	padding: 14px 16px;
	font-size: 16px;
	font-weight: 400;
	border-color: $neutral-300-value;
	font-family: $default-font !important;
	appearance: none;
	background-color: white;
	color: $negro;
}

.activeSelect {
	display: inherit;
}

.hiddenSelect {
	display: none;
}

.nativeSelectLabel {
	margin: 0;
	position: absolute;
	background-color: white;
	font-size: 12px;
	transform: translateX(8px) translateY(-8px);
	padding: 0 8px;
}

.collapsible {
	color: $AzulPrincipal;
	cursor: pointer;
	padding: 16px 16px;
	width: 100%;
	text-align: left;
	outline: none;
	font-size: 16px;
	background-color: white;
	font-weight: 700;
	font-family: $default-font !important;
	margin-top: 16px;
	border: 1px solid $neutral-300-value;
	border-radius: 10px;
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.hidden {
	.collapsible {
		border: 1px solid $neutral-300-value;
		border-radius: 10px;
	}

	.chevronArrowUp {
		display: none;
	}

	.chevronArrowDown {
		display: inherit;
	}
}

.chevron {
	border-right: 2px solid $AzulPrincipal;
	border-bottom: 2px solid $AzulPrincipal;
	width: 14px;
	height: 14px;
	position: absolute;
	right: 0px;
	pointer-events: none;
}

.chevronArrowUp {
	transform: translateY(-29px) translateX(-36px) rotate(-135deg);
}

.chevronArrowDown {
	display: none;
	transform: translateY(-39px) translateX(-36px) rotate(45deg);
}

.columnasComuna {
    @media (min-width: 768px) {
        column-count: auto;
        column-width: 200px;
        max-height: 440px;
        column-gap: 20px;
    }
}