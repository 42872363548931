@import "../../../styles/_variables.module.scss";

.contenedorDestacado {
    padding: $espaciado-64 0 0 0;
    font-family: $default-font;
    .ttDestacado {
        font: $ttSeccionMobile;
        line-height: 100%;
        color: $AzulPrincipal;
        margin: 0;
        text-align: center;
        margin-bottom: 5px;
    }
    .parrafoDestacado {
        text-align: center;
        margin: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
        color: $negro;
        margin-bottom: $espaciado-24;
        padding: 0 $espaciado-24;
    }
    .containerTabs {
        display: flex;
        justify-content: left;
        border-bottom: 2px solid $colorSecundario;
        margin-bottom: $espaciado-24;
        padding: 0 $espaciado-24;
        .tab {
            text-decoration: none;
            font: normal 400 16px/21px $default-font;
            color: $gris;
            cursor: pointer;
						margin-bottom: 0;
						display: initial;
            &:hover {
                color: $AzulPrincipal;
                font-weight: 600;
                border-bottom: 2px solid $AzulPrincipal !important;
            }
        }

        .tabActivo {
            @extend .tab;
            font-weight: 600!important;
            color: $AzulPrincipal !important;
            border-bottom: 2px solid $AzulPrincipal !important;
        }
    }
    .contenedorSlider {
        padding: 20px 0;
    }
    .arrowNext,
    .arrowPrev {
        display: none;
    }
    .sliderCard {
        padding-left: $espaciado-24;
    }
    .sinNavegacion{
        visibility: hidden;
    }
}

@media (min-width: 768px){
    .containerTabs {
        display: flex;
        justify-content: left;
        border-bottom: 2px solid $colorSecundario;
        margin-bottom: $espaciado-32;
        padding: 0 $espaciado-24;
        .tab {
            text-decoration: none;
            font: normal 400 16px/21px $default-font;
            color: $gris;
            margin-right: $espaciado-16;
        }
    }
}
@media (min-width: 992px) {
    .contenedorDestacado {
        .contenedorSlider {
            padding-bottom: 10px;
        }
        .ttDestacado {
            font: $ttSeccionDesktop;
            margin-bottom: 0;
        }
        .parrafoDestacado {
            font-size: 16px;
            margin-bottom: $espaciado-24;
        }
        .containerDesktop {
            .tab {
                margin-right: $espaciado-40;
            }
        }
        .containerTabs {
            box-sizing: border-box;
            margin-bottom: $espaciado-32;
        }
        .sliderCard {
            display: flex;
            align-items: center;
            padding: 0 20px;
            .arrowPrev {
                margin-right: $espaciado-40 !important;
                svg {
                    transform: rotate(90deg);
                }
            }
            .arrowNext {
                margin-left: $espaciado-40 !important;
                svg {
                    transform: rotate(-90deg);
                }
            }
            .arrowNext,
            .arrowPrev {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
                width: 48px !important;
                height: 48px !important;
                border-radius: 50%;
                color: $AzulPrincipal !important;
                padding: $espaciado-16;
                margin: auto;
            }
        }
    }
}
