@import "../../../styles/_variables.module.scss";

.searchResultRow {
	padding: $espaciado $espaciado-16;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;

	.searchResultRowIcon {
		margin-right: 8px;
	}

	.searchResultRowLabel {
		font-size: 18px;

		span {
			font-weight: 600;
			display: block;
			color: inherit;
			background-color: inherit;
		}

		label {
			display: block;
			color: $gris;
			color: inherit;
			background-color: inherit;
		}
	}
}